@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hides the Arrow Chevrons on Input Number Type */
@layer utilities {
  input[type='time']::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
  }

  /* Chrome, Safari, Edge, Opera */
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

body, html {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
}

.react-headless-notifier-top-0 {
  @apply w-full max-w-[611px] top-3 m-auto z-10;
}

.url-text {
  @apply transition outline-none cursor-pointer focus:underline hover:underline text-primary hover:text-primary hover:brightness-90;
}

.text-xxs {
  font-size: 0.5rem;
  line-height: 0.75rem;
}

.informational-html>h2 {
  @apply mt-3 mb-1 text-base font-bold;
}

.informational-html>p {
  @apply py-1 text-xs;
}

.informational-html>ul {
  @apply py-2 pl-4 text-xs list-disc list-inside;
}

.informational-html a {
  @apply cursor-default text-primary hover:brightness-90;
}

.homepage-header {
  font-family: 'Montserrat', sans-serif;
}

.homepage-body {
  font-family: 'Lato', sans-serif;
}

.react-select-container {
  @apply flex items-center max-w-sm w-full border border-secondary-gray active:border-primary focus:border-primary !rounded-md;
}

.react-select__control {
  @apply w-full !transition-none !border-none !rounded-md  !min-h-[34px] bg-green-300;
}

.react-select__control--is-focused {
  @apply !shadow-none !transition-none !ring-[2px] ring-primary z-50;
}

.react-select__value-container {
  @apply !px-2 !py-0.5 !transition-all;
}

.react-select__placeholder {
  @apply font-medium !text-gray-400;
}

.react-select__input {
  @apply !text-black !font-medium;
}

.break-word {
  word-break: break-word;
}