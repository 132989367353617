/* CSS variables. */
:root {
	--PhoneInputInternationalIconPhone-opacity: 0.8;
	--PhoneInputInternationalIconGlobe-opacity: 0.65;
}

/* This is done to stretch the contents of this component. */
.PhoneInput {
  @apply flex items-center w-full;
}

/* Input */
.PhoneInputInput {
	@apply flex w-full p-2 border border-secondary-gray rounded-r-md focus:ring-1 h-full;
}

.PhoneInputDisabled>.PhoneInputInput {
	@apply bg-disabled text-disabled-dark
}

/* Flag Box */
.PhoneInputCountryIcon {
  @apply flex items-center w-[48px] h-[42px] p-3 border border-r-0 rounded-l-md bg-gray-50 flex-shrink-0;
}

.PhoneInputLabel>.PhoneInputCountry>.PhoneInputCountryIcon {
	@apply sm:rounded-l-none rounded-l-md
}

.PhoneInputCountryIcon--border {
	@apply border-secondary-gray border border-r-0 rounded-l-md focus:ring-1
}

/* Flag */
.PhoneInputCountryIconImg {
  @apply items-center block;
}

.PhoneInputDisabled>.PhoneInputCountry>.PhoneInputCountryIcon>.PhoneInputCountryIconImg {
	@apply opacity-30;
}

/* International Icon */
.PhoneInputInternationalIconPhone {
	opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
	opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

.PhoneInputCountry {
	@apply relative flex items-center self-stretch;
}

.PhoneInputCountrySelect {
	@apply absolute top-0 left-0 h-full w-full z-[1] opacity-0 cursor-pointer;
}

.PhoneInputCountrySelect[disabled],
.PhoneInputCountrySelect[readonly] {
	cursor: default;
}