.rdp {
  @apply border border-secondary-gray rounded p-3 shadow bg-white m-0 mt-2;
}

.rdp-caption_label {
  @apply text-lg;
}

.rdp-day_today {
  @apply text-primary;
}

.rdp-day_range {
  @apply !bg-primary
}

.rdp-day_selected {
  @apply hover:!bg-primary !bg-primary !text-white;
}

.rdp-button {
  @apply focus:!outline-primary focus:!ring-primary focus:!border-primary;
}