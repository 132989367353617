/* .quill>.ql-toolbar:not(:only-of-type):first-child {
  display: none !important;
} */

.quill>.ql-container>.ql-editor {
  @apply max-h-72
}

.ql-toolbar {
  @apply rounded-t-md bg-white
}

.ql-container {
  @apply rounded-b-md bg-white h-auto
}

.ql-editor>h1,
.quill-message>h1 {
  font-family: 'Montserrat', sans-serif;
  @apply text-2xl font-medium 
}

.ql-editor>h2,
.quill-message>h2 {
  font-family: 'Montserrat', sans-serif;
  @apply text-xl
}

.ql-editor>h3,
.quill-message>h3 {
  font-family: 'Montserrat', sans-serif;
  @apply text-base
}

.ql-editor>p,
.quill-message>p {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
}

.ql-editor>p>a,
.quill-message>p>a {
  @apply text-sm text-blue-500 hover:text-blue-600 transition-colors hover:cursor-pointer hover:underline
}

.quill-message>ul {
  @apply list-disc list-inside
}

.quill-message>ol {
  @apply list-decimal list-inside
}
